.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  color: black;
  margin-left: 25%;
  margin-right: 25%;
}

.image {
  text-align: center;
}

.image img {
  max-width: 100%;
  width: 300px;
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.image_description {
  text-align: center;
  display: flex;
  align-items: center;
}

.image_description h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.image_description p {
  font-size: 14px;
}

@media (max-width: 1000px) {
  .grid_container {
    grid-template-columns: 1fr;
    margin-left: 50px;
    margin-right: 50px;
  }
}